import { RouteComponentProps } from '@reach/router'
import {
  Box,
  VisuallyHidden,
  Text,
  List,
  IconSend,
  IconCredits,
  IconValue,
  IconProps,
  TwoColorSVGProps,
} from '@butcherbox/freezer'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { UserContext } from '~/context/user'
import YotpoModule, { useYotpoModuleRollout } from '~/sections/YotpoModule'
import * as Styles from '~/routes/Credits/Credits.css'

interface IExample {
  icon: React.FC<IconProps<TwoColorSVGProps>>
  title: string
  description: string
}

function Example({ description, icon: Icon, title, ...props }: IExample) {
  return (
    <Box className={Styles.CreditsItem} component="li" {...props}>
      <Box className={Styles.Icon}>
        <Icon size="regular" variant="duo" />
      </Box>
      <Text component="h2" my={8} variant="H2Regular">
        {title}
      </Text>

      <Text color="stone" variant="H4Bold">
        {description}
      </Text>
    </Box>
  )
}

const AccountCredits: React.FC<RouteComponentProps> = () => {
  const user = React.useContext(UserContext)
  const { renderModule } = useYotpoModuleRollout()

  return (
    <Box data-cy={TEST_ID.ACCOUNT_CREDITS}>
      <VisuallyHidden>
        <h1>Account Credits</h1>
      </VisuallyHidden>
      {(() => {
        switch (renderModule) {
          case 'yotpo':
            return (
              <YotpoModule
                customerEmail={user.emailAddress}
                customerId={user.id}
              />
            )
          case 'loading':
          default:
            return (
              <Box alignItems="center" justifyContent="center">
                <LoadingSpinner />
              </Box>
            )
        }
      })()}

      <Box className={Styles.CreditsBlock}>
        <Text component="h2" variant="H2Regular">
          How it works
        </Text>
        <List className={Styles.ItemsGrid}>
          <Example
            description="Invite your friends so they can get $50 off their first box"
            icon={IconSend}
            title="1. Share with a friend"
          />
          <Example
            description="For every friend that signs up, you will earn a $50 credit once they become a member."
            icon={IconCredits}
            title="2. Earn a $50 credit"
          />
          <Example
            description="Credits will be automatically applied to your account to use on your next box. The more you refer, the more you save!"
            icon={IconValue}
            title="3. Credits are automatically applied"
          />
        </List>
        <Text
          color="stone"
          marginTop={24}
          mx={16}
          textAlign={'center'}
          variant="Micro"
        >
          *ButcherBox may modify or cancel this offer at any time in its sole
          discretion.
        </Text>
      </Box>
    </Box>
  )
}

export default AccountCredits
